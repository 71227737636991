
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        


























































@import '@/styles/_colors.scss';

.users__list {
  display: flex;
  flex-direction: column;
  margin-right: -1rem;
  margin-bottom: 1rem;
  margin-left: -1rem;
  border-bottom: 1px solid $color-grey-100;
}

.user__link {
  display: flex;
  align-items: center;
  color: var(--color-text);
  text-decoration: none;
}

.user__image {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.35rem;
  border-radius: 1rem;
}

.user {
  padding: 0.5rem 1rem;
  border-top: 1px solid $color-grey-100;
}
